<template>
  <!-- 移库管理 -->
  <div class="shiftingParking">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr">
      <template>
        <el-form-item
          label="仓库名称"
          prop="warehouseName"
        >
          <el-select
            v-model="formInline.warehouseName"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="warehouseNameremoteMethod"
          >
            <el-option
              v-for="item in warehouseNameArr"
              :key="item.warehouseName"
              :label="item.warehouseName"
              :value="item.warehouseName"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table :item-data="itemData" :list-data="listData" :loading="loading" :operation-button="operationButton" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import { timestampToTime } from '../../utils/util'
import { inventoryMovePage, warehouseList } from '../../api/warehousingSystem'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '移库单号', prop: 'moveNo', width: 180 },
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '货主名称', prop: 'custName', width: 180 },
        { label: '移库日期', prop: 'moveDate', width: 180 },
        { label: '操作人', prop: 'operator', width: 180 },
        { label: '创建时间', prop: 'modifyTime', width: 180 }
      ],
      formItemArr: [
        { type: 'input', width: '130', label: '货主名称', value: 'custName' },
        { type: 'picker', label: '移库日期', value: 'timeArr', startTimer: 'createTimeStart', endTimer: 'createTimeEnd' },
        { type: 'input', label: '操作人', value: 'operator' }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      warehouseNameArr: []
    }
  },
  mounted() {
    this.warehouseNameremoteMethod()
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inventoryMovePage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.modifyTime = timestampToTime(item.modifyTime)
        })
        this.total = res.data.total
      })
    },
    // 联动搜索仓库列表
    warehouseNameremoteMethod(value) {
      warehouseList(value || '', res => {
        this.warehouseNameArr = res.data
      })
    },
    viewDetails(row) {
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push('/warehousingSystem/relocationDetails')
    }
  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}
.marginTop {
  padding-top: 18px;
}
</style>
